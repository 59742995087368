import { PaletteOptions } from "@mui/material"

// Define custom color types

interface OtherColorTypes {
  divider: string
  border: string
}
declare module "@mui/material/styles" {
  interface SimplePaletteColorOptions {
    states?: {
      outlinedRestingBorder?: string
      outlinedHoverBackground?: string
      containedHoverBackground?: string
    }
    alert?: {
      content?: string
      background?: string
    }
  }
  interface PaletteColor {
    states?: {
      outlinedRestingBorder?: string
      outlinedHoverBackground?: string
      containedHoverBackground?: string
    }
    alert?: {
      content?: string
      background?: string
    }
  }
  interface Palette {
    mute: SimplePaletteColorOptions
    other: OtherColorTypes
  }
  interface PaletteOptions {
    mute: SimplePaletteColorOptions
    other: OtherColorTypes
  }
}

const GREY_TONES = {
  100: "#E4E4E4",
  200: "#A7A7A7",
  300: "#7D7D7D",
  500: "#404040",
  700: "#252525",
}
const lightPalette: PaletteOptions | undefined = {
  mode: "light",
  primary: {
    main: "#06B4D7",
    dark: "#034577",
    light: "#83EBEB",
    states: {
      outlinedRestingBorder: "#06B4D7",
      outlinedHoverBackground: "#E6FFFF",
      containedHoverBackground: "#0386A1",
    },
  },
  secondary: {
    main: "#E6AD76",
    dark: "#D07647",
    light: "#DEFDFF",
    states: {
      outlinedRestingBorder: "#E6AD76",
      outlinedHoverBackground: "#DEFDFF",
      containedHoverBackground: "#D07647",
    },
  },
  mute: {
    main: "#DEFDFF",
    light: "#DEFDFF",
    dark: "#ACF9FC",
    states: {
      containedHoverBackground: "#ACF9FC",
    },
  },
  info: {
    main: "#8211F2",
    states: {
      outlinedRestingBorder: "#8211F2",
      outlinedHoverBackground: "#C9B8FB",
      containedHoverBackground: "#2F099F",
    },
    alert: {
      background: "#C9B8FB",
    },
  },
  background: {
    default: "#FBF5FF",
    paper: "#FFFFFF",
  },
  action: {
    hover: "#DEFDFF",
    hoverOpacity: 0.1,
    active: "#FFEA91",
    disabled: "#A7A7A7",
  },
  success: {
    main: "#06B4D7",
    dark: "#034577",
    light: "#83EBEB",
    alert: {
      background: "#E6FFFF",
    },
  },
  error: {
    main: "#FB5A5A",
    dark: "#68282F",
    light: "#FDA49A",
    alert: {
      background: "#FEECEB",
    },
  },
  warning: {
    main: "#FAEA5D",
    dark: "#817F48",
    light: "#FFEA91",
    alert: {
      content: "#000000",
      background: "#FFF3C8",
    },
  },
  text: {
    primary: "#000000",
    secondary: "#404040",
    disabled: "#A7A7A7",
  },
  divider: "#ACF9FC",
  grey: GREY_TONES,
  other: {
    divider: "#ACF9FC",
    border: "#7D7D7D",
  },
}

const darkPalette: PaletteOptions | undefined = {
  mode: "dark",
  primary: {
    main: "#06B4D7",
    dark: "#034577",
    light: "#83EBEB",
    states: {
      outlinedRestingBorder: "#06B4D7",
      outlinedHoverBackground: "#034577",
      containedHoverBackground: "#014F94",
    },
  },
  secondary: {
    main: "#06B4D7",
    dark: "#034577",
    light: "#DEFDFF",
    states: {
      outlinedRestingBorder: "#06B4D7",
      outlinedHoverBackground: "#034577",
      containedHoverBackground: "#DEFDFF",
    },
  },
  mute: {
    main: "#521188",
    light: "#8211F2",
    dark: "#380B5B",
    states: {
      containedHoverBackground: "#8211F2",
    },
  },
  info: {
    main: "#8211F2",
    dark: "#2F099F",
    light: "#9B91FF",
    states: {
      outlinedRestingBorder: "#8211F2",
      outlinedHoverBackground: "#270782",
      containedHoverBackground: "#C9B8FB",
    },
  },

  action: {
    hover: "#521188",
    active: "#8211F2",
    disabled: "#A7A7A7",
    disabledBackground: "#404040",
    hoverOpacity: 0.5,
    selected: "#8211F2",
  },
  success: {
    main: "#06B4D7",
    dark: "#034577",
    light: "#83EBEB",
    alert: {
      background: "#E6FFFF",
    },
  },
  error: {
    main: "#FB5A5A",
    dark: "#68282F",
    light: "#FDA49A",
    alert: {
      background: "#FEECEB",
    },
  },
  warning: {
    main: "#FAEA5D",
    dark: "#817F48",
    light: "#FFEA91",
    alert: {
      content: "#000000",
      background: "#FFF3C8",
    },
  },
  text: {
    secondary: "#E4E4E4",
    disabled: "#7D7D7D",
  },
  background: {
    default: "#1F0530",
    paper: "#181234",
  },
  divider: "#521188",
  other: {
    divider: "#521188",
    border: "#7D7D7D",
  },
  grey: GREY_TONES,
}
export default { lightPalette, darkPalette }
