/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AppDispatch } from "../state"
import { ChainId } from "./../constants/index"
import retry from "async-retry"
import { updateSwapStats } from "../state/application"
// import { SwapData } from "../hooks/useCalculateSwapPairs"

// const swapStatsURI = "https://random.com/swap-stats.json"

export type SwapStatsReponse = {
  [chainId in ChainId]?: {
    [swapAddress: string]: {
      oneDayVolume: number
      APY: number
      TVL: number
    }
  }
}

// fetch("https://api.thegraph.com/subgraphs/name/croc12344/mm-stableswap", {
//   headers: {
//     accept: "*/*",
//     "accept-language": "en-GB,en-US;q=0.9,en;q=0.8",
//     "content-type": "application/json",
//     "sec-ch-ua":
//       '"Chromium";v="104", " Not A;Brand";v="99", "Google Chrome";v="104"',
//     "sec-ch-ua-mobile": "?0",
//     "sec-ch-ua-platform": '"macOS"',
//     "sec-fetch-dest": "empty",
//     "sec-fetch-mode": "cors",
//     "sec-fetch-site": "same-site",
//   },
//   referrer: "https://thegraph.com/",
//   referrerPolicy: "strict-origin-when-cross-origin",
//   body: '{"query":"{\\n  liquidityPoolDailySnapshots(orderBy:timestamp, orderDirection: desc, first:1) {\\n    dailyVolumeUSD\\n    totalValueLockedUSD\\n    timestamp\\n  }\\n}","variables":null}',
//   method: "POST",
//   mode: "cors",
//   credentials: "omit",
// })

const fetchSwapStatsNow = (): Promise<SwapStatsReponse> =>
  fetch(`https://api.thegraph.com/subgraphs/name/croc12344/mm-stableswap`, {
    cache: "no-cache",
    body: '{"query":"{\\n  liquidityPoolDailySnapshots(\\n    orderBy: timestamp\\n    orderDirection: desc\\n    first: 2\\n    where: {pool_: {id: \\"0x690bbaa9edbb762542fd198763092eab2b2a5350\\"}}\\n  ) {\\n    dailyVolumeUSD\\n    totalValueLockedUSD\\n  }\\n}","variables":null,"extensions":{"headers":null}}',
    method: "POST",
    mode: "cors",
  })
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return res.json()
      }
      throw new Error("Unable to fetch swap stats from IPFS")
    })
    .then((body: any) => {
      // console.log(body.data)
      const volume = parseInt(
        body.data.liquidityPoolDailySnapshots[0].dailyVolumeUSD as string,
      )
      const totalValueLockedUSD = parseInt(
        body.data.liquidityPoolDailySnapshots[0].totalValueLockedUSD as string,
      )
      const apy = (volume * 365 * (0.02 / 100)) / totalValueLockedUSD
      console.log(body.data.liquidityPoolDailySnapshots[0], apy)

      const response: SwapStatsReponse = {
        137: {
          "0x690BBaa9EDBb762542FD198763092eaB2B2A5350": {
            oneDayVolume: volume || 0,
            APY: 0.056,
            TVL: 0,
          },
        },
      }
      return response
    })

export default async function fetchSwapStats(
  dispatch: AppDispatch,
): Promise<void> {
  const dispatchUpdate = (swapStats: SwapStatsReponse) => {
    dispatch(updateSwapStats(swapStats))
  }
  await retry(() => fetchSwapStatsNow().then(dispatchUpdate), {
    retries: 3,
  })
}
